import React, { useEffect, useState } from "react";
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import CloseIcon from '@rsuite/icons/Close';
import { Button, Input, InputGroup } from "rsuite";
import { PartnerGetLiveChatMessagesFetch, PartnerLiveChatNewMessageFetch, PartnerPingLiveChatMessagesFetch } from "../../connector/connectorV2";
import RemindRoundIcon from '@rsuite/icons/RemindRound';

export default function LiveChart(){
    const [open, set_open] = useState(false);
    const [new_messages, set_new_messages ] = useState(0);
    const [chat, set_chat] = useState(null)
    const [new_message, set_new_message] = useState("")
    const PingAction = () => {
        PartnerPingLiveChatMessagesFetch().then(res => {
            set_new_messages(res.data)
        })
    }
    const GetChatAction = () => {
        PartnerGetLiveChatMessagesFetch().then(res => {
            set_new_messages(0)
            set_chat(res.data)
        })
    }
    const MainIntervalFunction = () => {
        if (open) {
            GetChatAction()
        } else {
            PingAction()
        }
    }
    const SendMessage = () => {
        if (new_message == null || new_message == "") return;
        set_new_message("")
        const new_messages = [...(chat.messages || []), {from_type: "PARTNER", text: new_message, create_datetime: new Date().toLocaleString()}]
        const new_chat_data = {...chat, ...{messages: new_messages}}
        set_chat(new_chat_data)
        PartnerLiveChatNewMessageFetch({message: {text: new_message}})
    }
    useEffect(() => {
        PingAction()
        const intervalId = setInterval(() => {MainIntervalFunction()}, 5000);
        return () => {
            clearInterval(intervalId);
        }
    }, [open])
    return (
        <div className="live_chart_fly">
            {!open && 
                <div className="rowv fuq_row cursorPointer" onClick={(e) => {set_open(true); GetChatAction()}}>
                    <div className="bbn3">
                       {new_messages == 0 &&
                       <>
                            <div className="bbn1">Поддержка</div>
                            <div className="bbn4">(<div className="round green"></div> <div>online</div>)</div>
                            <HelpOutlineIcon />
                        </>}
                        {new_messages != 0 && 
                            <><RemindRoundIcon /><div className="bbn1">новое сообщение</div></>}
                    </div>
                    
                </div>
            }
            {open &&
                <div className="rowv fuq_col">
                    <div className="bbn2" onClick={(e) => {set_open(false)}}><CloseIcon /></div>
                    <div className="bbn3"><div className="bbn1">Чат с техподдержкой</div><div className="bbn4"><div className="round green"></div> <div>online</div></div></div>
                    <div className="message_wrapper scroll_stylle">
                        {chat?.messages?.map((val, i) => {
                            if (val.from_type == "ADMIN"){
                                return <div key={`chat-message-item-key-${i}`} className="message message_from_admin">{val.text}</div>
                            } else if (val.from_type == "PARTNER"){
                                return <div key={`chat-message-item-key-${i}`} className="message message_from_user">{val.text}</div>
                            }
                        })}
                    </div>
                    <div className="message_drive_wrapper">
                        <InputGroup className="bbn5">
                            <Input value={new_message} onChange={(val) => {set_new_message(val)}} />
                            <InputGroup.Addon>
                                <Button className="bbn6" onClick={(e) => {SendMessage()}}>Отправить</Button>
                            </InputGroup.Addon>
                        </InputGroup>
                    </div>
                </div>
            }

            
        </div>
    )
}