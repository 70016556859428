import React from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export default function OfficeMap(props){
    return (
        <YMaps
        >
            <div>
                <Map width={"100%"} height={props.height ? props.height : "200px"} defaultState={{ center: [55.75, 37.57], zoom: 11 }}>
                    <Placemark modules={["geoObject.addon.balloon"]} geometry={[55.75, 37.57]} properties={{
                        balloonContentBody:
                        "Главный офис PM <br/> ул. Ленина 38 стр. 2",
                    }} />
                </Map>
            </div>
        </YMaps>
    )
}