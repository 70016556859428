import React, { useState } from "react";
import { Modal } from "rsuite";
import MoveUpIcon from '@rsuite/icons/MoveUp';
import MoveDownIcon from '@rsuite/icons/MoveDown';
import ReloadIcon from '@rsuite/icons/Reload';

import MinusRoundIcon from '@rsuite/icons/MinusRound';
import PlusRoundIcon from '@rsuite/icons/PlusRound';

export default function ImageModal(props){
    const [open, setOpen] = useState(false)
    const [curImage, setCurImage] = useState(null)
    const [rotate, set_rotate] = useState(0);
    const [img_width, set_img_width] = useState(100);

    const rotate_right = () => {
        set_rotate(rotate + 90);
    }
    const rotate_left = () => {
        set_rotate(rotate - 90);
    }
    const increase_img_width = () => {
        const new_img_width = Math.min(img_width + 10, 200)
        set_img_width(new_img_width)
    }
    const decrease_img_width = () => {
        const new_img_width = Math.max(img_width - 10, 20)
        set_img_width(new_img_width)
    }
    const handleOpen = (img) => {setCurImage(img);setOpen(true)}
    const handleClose = () => {setCurImage(null); setOpen(false)}
    return (
        <>
            <div className="modal_images_pre_wrapper">
                {props.value?.map(val => <img onClick={(e) => {handleOpen(val)}} src={val} className="modal_pre_image" />)}
            </div>
            
            
            <Modal open={open} onClose={handleClose} className="image_modal_modal">
                <Modal.Header>
                    <div className="zzio1">
                        <Modal.Title>Изобажение</Modal.Title>
                        <div className="img_modal_flight">
                            <MoveUpIcon className="cursorPointer" onClick={rotate_left} style={{"transform": `rotate(0deg)`}}/>
                            <MoveDownIcon className="cursorPointer" onClick={rotate_right} />
                        </div>
                    </div>
                    
                </Modal.Header>
                <Modal.Body style={{position: "relative"}}>
                    <div className="real_image_modal_flight">
                        <MinusRoundIcon className="cursorPointer" onClick={decrease_img_width} />
                        <PlusRoundIcon className="cursorPointer" onClick={increase_img_width} />
                    </div>
                    <div className="img_modal_modal_www scroll_stylle">
                        
                        <img src={curImage} style={{transform: `rotate(${rotate}deg)`, width: `${img_width}%`}} className="modal_body_img" />
                    </div>
                    
                </Modal.Body>
            </Modal>
        </>
    )
}