import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Sidenav, Nav, Toggle } from 'rsuite';
import ExploreIcon from '@rsuite/icons/Explore';
import PeoplesIcon from '@rsuite/icons/Peoples';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import WechatTemplateIcon from '@rsuite/icons/WechatTemplate';
import GearIcon from '@rsuite/icons/Gear';
import logo from "./../../../source/svg/logo.svg";
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import ExitIcon from '@rsuite/icons/Exit';
import WechatIcon from '@rsuite/icons/Wechat';
import "./adminHeader.css";

function AdminHeader(props){
    const [expanded, setExpanded] = useState(false);
    const [openKeys, setOpenKeys] = useState(["1"]);
    const navigate = useNavigate()
    return (
        <>
        <div className='header_wrapper'>
        <Sidenav className="main_side_nav" expanded={expanded} onOpenChange={setOpenKeys}>
                
                <Sidenav.Body >
                    <img src={logo} className="ttz8" />
                    <Nav>
                        {props.access_dict.includes("departures") &&
                        <Nav.Item className='ttz7' eventKey="1" icon={<ExploreIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/departures")}}>
                            Отправления
                        </Nav.Item>}
                        {props.access_dict.includes("partners") &&
                        <Nav.Item className='ttz7' eventKey="2" icon={<PeoplesIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/partners")}}>
                            Партнеры
                        </Nav.Item>}
                        {props.access_dict.includes("admins") &&
                        <Nav.Item className='ttz7' eventKey="3" icon={<PeoplesIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/administrators")}}>
                            Администраторы
                        </Nav.Item>}
                        {props.access_dict.includes("tickets") &&
                        <Nav.Item className='ttz7' eventKey="4" icon={<UserBadgeIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/tickets")}}>
                            Билеты
                        </Nav.Item>}
                        {props.access_dict.includes("applications") &&
                        <Nav.Item className='ttz7' eventKey="5" icon={<WechatTemplateIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/applications")}}>
                            Заявки
                        </Nav.Item>}
                        {props.access_dict.includes("content") &&
                        <Nav.Item className='ttz7' eventKey="7" icon={<GearIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/content")}}>
                            Контент
                        </Nav.Item>}
                        <Nav.Item className='ttz7' eventKey="6" icon={<WechatIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/chat")}}>
                            Чат
                        </Nav.Item>
                        {props.access_dict.includes("settings") &&
                        <Nav.Item className='ttz7' eventKey="6" icon={<GearIcon />} onClick={(e) => {setExpanded(false); navigate("/administrator/settings")}}>
                            Настройки
                        </Nav.Item>}
                        
                        <Nav.Item className='ttz7 uui1' eventKey="8" icon={<ExitIcon/>} onClick={(e) => {setExpanded(false); navigate("/")}}>
                            Выйти
                        </Nav.Item>
                    </Nav>
                </Sidenav.Body>
                <Sidenav.Toggle  className='ttz9' onToggle={expanded => setExpanded(expanded)} />
            </Sidenav>
        </div>
        <div className='mobile mobile_expand' onClick={(e) => {setExpanded(true)}}><ArrowRightLineIcon color='rgba(0,0,0,.6)' height={30} width={30} /></div>
        </>
    )
}

export default AdminHeader;