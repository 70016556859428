import React from "react";
import "./partner_calendar.css"
import { Calendar, Badge, List, HStack } from 'rsuite';

function getTodoList(date) {
    if (!date) {
      return [];
    }
    const day = date.getDate();
  
    switch (day) {
      case 10:
        return [
          { time: '10:30 am', title: 'Kick-off 2025' },
          { time: '12:00 pm', title: 'Бизнес-ланч Kick-off 2025' }
        ];
      case 15:
        return [
          { time: '09:30 pm', title: 'Products Introduction Meeting' },
          { time: '12:30 pm', title: 'Client entertaining' },
          { time: '02:00 pm', title: 'Product design discussion' },
          { time: '05:00 pm', title: 'Product test and acceptance' },
          { time: '06:30 pm', title: 'Reporting' }
        ];
      default:
        return [];
    }
  }
  
  function renderCell(date) {
    const list = getTodoList(date);
  
    if (list.length) {
      return <Badge className="calendar-todo-item-badge" />;
    }
  
    return null;
  }
const TodoList = ({ date }) => {
    const list = getTodoList(date);
  
    if (!list.length) {
      return <div className="special_no_mercy">На этот день планов нет</div>;
    }
  
    return (
      <List style={{ flex: 1 }} bordered>
        {list.map(item => (
          <List.Item key={item.time} index={item.time}>
            <div>{item.time}</div>
            <div>{item.title}</div>
          </List.Item>
        ))}
      </List>
    );
  };

export default function PartnerCalendar(props){
    const [selectedDate, setSelectedDate] = React.useState(null);

  const handleSelect = date => {
    setSelectedDate(date);
  };
    return (
        <div className="bnv1">
            <div className="title">Календарь мероприятий</div>
            <div className="hstack_fnck">
                <Calendar compact isoWeek renderCell={renderCell} onSelect={handleSelect} className="partn_calendar" />
                <TodoList date={selectedDate} />
            </div>
        </div>
    )
}