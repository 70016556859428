import React, { useState, useRef } from "react";
import logo from "./../../source/svg/logo.svg";
import "./invite.css";
import Checkbox from "../atoms/checkbox/checkbox";
import { regInputs, mainTabs } from "./constants";
import { partnerConnector, authConnector } from "../connector/connector";
import { ForgetFetch, RegistrationFetch } from "../connector/connectorV2";

function InTab(props){
    return (
        <div className="invTabCont">
            <div className="invite_inputContainer invite_size">
                <input ref={props.logRef} className="simple_input" type="text" value={props.login} onChange={(e) => {props.setLogin(e.target.value)}} placeholder="Id" />
            </div>
            <div className="invite_row invite_size">
                <Checkbox id="save_user" after_text="Запомнить меня" />
                <div className="simple_btn" onClick={(e) => {props.inviteAction()}}>Войти</div>
            </div>
            <div className="forget_password_container invite_size">
                <div className="forget_text cursorPointer" onClick={(e) => {props.setActiveTab("forget")}}>Забыли пароль?</div>
            </div>
        </div>
    )
}

function RegTab({setActiveTab}){
    const [reg_data, set_reg_data] = useState({})
    const [bads, set_bads] = useState(null)

    const UpdateRegData = (key, value) => {
        set_bads(null)
        let new_reg_data = {...reg_data}
        new_reg_data[key] = value
        set_reg_data(new_reg_data) 
    }
    const RegistrationAction = () => {
        if (reg_data?.password != reg_data?.passsword_retry){
            set_bads("Пароли не совпадают")
            return
        }
        if (!reg_data?.partner_id?.trim() || !reg_data?.name?.trim() || !reg_data?.surname?.trim()
            || !reg_data?.phone?.trim() || !reg_data?.email?.trim() || !reg_data?.password?.trim() || !reg_data?.passsword_retry?.trim()){
            set_bads("Введите всю необходимую информацию")
            return
        }
        RegistrationFetch(reg_data).then(res => {
            if(res.status != 200){
                set_bads("Партнер с таким ID уже зарегистрирован")
                return
            } else {
                setActiveTab("success_registration")
            }
            
        })
    }
    return (
        <div className="invTabCont">
            <div className="invite_inputContainer invite_size">
                <input className="simple_input" type="number" value={reg_data.partner_id} onChange={(e) => {UpdateRegData("partner_id", e.target.value)}} placeholder="Партнерский Id" />
                <input className="simple_input" type="text" value={reg_data.name} onChange={(e) => {UpdateRegData("name", e.target.value)}} placeholder="Имя" />
                <input className="simple_input" type="text" value={reg_data.surname} onChange={(e) => {UpdateRegData("surname", e.target.value)}} placeholder="Фамилия" />
                <input className="simple_input" type="text" value={reg_data.phone} onChange={(e) => {UpdateRegData("phone", e.target.value)}} placeholder="Телефон" />
                <input className="simple_input" type="text" value={reg_data.email} onChange={(e) => {UpdateRegData("email", e.target.value)}} placeholder="Email" />
                <input className="simple_input" type="password" value={reg_data.password} onChange={(e) => {UpdateRegData("password", e.target.value)}} placeholder="Пароль" />
                <input className="simple_input" type="password" value={reg_data.passsword_retry} onChange={(e) => {UpdateRegData("passsword_retry", e.target.value)}} placeholder="Повторите пароль" />
            </div>
            { bads != null &&
                <div className="red_bad_div">{bads}</div>
            }
            <div className="invite_row invite_size">
                <div className="simple_btn" onClick={RegistrationAction}>Зарегистрироваться</div>
            </div>
        </div>
    )
}

function ForgetTab({setActiveTab}){
    const [reg_data, set_reg_data] = useState({})
    const [bads, set_bads] = useState(null)
    const UpdateRegData = (key, value) => {
        set_bads(null)
        let new_reg_data = {...reg_data}
        new_reg_data[key] = value
        set_reg_data(new_reg_data) 
    }
    const ForgetAction = () => {
        if (!reg_data?.partner_id?.trim() || !reg_data?.email?.trim()){
            set_bads("Введите всю необходимую информацию")
            return
        }
        ForgetFetch(reg_data).then(res => {
            if (res.status != 200){
                set_bads("Парнер с указанными данными не найден")
            } else {
                setActiveTab("forget_success")
            }
            
        })
    }
    return (
        <div className="invTabCont">
            <div className="invite_inputContainer invite_size">
                <input className="simple_input" type="number" value={reg_data.partner_id} onChange={(e) => {UpdateRegData("partner_id", e.target.value)}} placeholder="Партнерский Id" />
                <input className="simple_input" type="text" value={reg_data.email} onChange={(e) => {UpdateRegData("email", e.target.value)}} placeholder="Email" />
            </div>
            { bads != null &&
                <div className="red_bad_div">{bads}</div>
            }
            <div className="invite_row invite_size">
                <div className="simple_btn" onClick={ForgetAction}>Восстановить</div>
            </div>
        </div>
    )
}

function CodeTab(props){
    return (
        <div className="invTabCont">
            <div className="invite_inputContainer invite_size">
                <input ref={props.codeRef} className="simple_input" type="text" value={props.code} onChange={(e) => {props.setCode(e.target.value)}} placeholder="Код авторизации" />
            </div>
            <div className="invite_row invite_size">
                <div className="simple_btn" onClick={(e) => {props.AcceptCode()}}>Далее</div>
            </div>
            <div className="forget_password_container invite_size">
                <div className="forget_text cursorPointer" onClick={(e) => {props.setActiveTab("login")}}>Назад</div>
                <div className="forget_text cursorPointer" onClick={(e) => {props.RequestCode()}}>Запросить код повторно</div>
            </div>
        </div>
    )
}
function NewPassTab(props){
    return (
        <div className="invTabCont">
            <div className="invite_inputContainer invite_size">
                <input ref={props.newPassRef} className="simple_input" value={props.newPass} onChange={(e) => {props.setNewPass(e.target.value)}} type="password" placeholder="Пароль" />
                <input ref={props.newPassRetryRef} className="simple_input" value={props.newPassRetry} onChange={(e) => {props.setNewPassRetry(e.target.value)}} type="password" placeholder="Повторите пароль" />
            </div>
            <div className="invite_row invite_size">
                <div className="simple_btn" onClick={(e) => {props.AcceptUser()}}>зарегистрироваться</div>
            </div>
            <div className="forget_password_container invite_size">
                <div className="forget_text cursorPointer" onClick={(e) => {props.setActiveTab("login")}}>Назад</div>
            </div>
        </div>
    )
}
function PassTab(props){
        
    return (
        <div className="invTabCont">
            <div className="invite_inputContainer invite_size">
                <input ref={props.passRef} value={props.password} onChange={(e) => {props.setPassword(e.target.value)}} className="simple_input" type="password" placeholder="Пароль" />
            </div>
            <div className="invite_row invite_size">
                <Checkbox id="save_user" after_text="Запомнить меня" />
                <div className="simple_btn" onClick={(e) => {props.inviteAction2()}}>Войти</div>
            </div>
            <div className="forget_password_container invite_size">
                <div className="forget_text cursorPointer" onClick={(e) => {props.setActiveTab("login")}}>Назад</div>
            </div>
        </div>
    )
}

function WaitingTab({setActiveTab, text}){
    return (
        <div className="invTabCont">
            <div className="invite_inputContainer invite_size ttz101">
                {text}
            </div>
            <div className="forget_password_container invite_size">
                <div className="forget_text cursorPointer" onClick={(e) => {setActiveTab("login")}}>Назад</div>
            </div>
        </div>
    )
}

export default function Invite(props){
    const [activeTab, setActiveTab] = useState("login");
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [code, setCode] = useState("")
    const [newPass, setNewPass] = useState("")
    const [newPassRetry, setNewPassRetry] = useState("")
    const logRef = useRef()
    const codeRef = useRef()
    const newPassRef = useRef()
    const newPassRetryRef = useRef()
    const passRef = useRef()
    const RequestCode = () => {
        authConnector.connect_async("init_code", {login: login})
    }
    const AcceptCode = () => {
        authConnector.connect_async("accept_code", {login: login, code: code})
        .then(res => {
            setActiveTab("new_pass")
        }, err => {
            codeRef.current.style.border = "1px solid red"
        })
    }
    const inviteAction2 = (pass = password) => {
        partnerConnector.login(login, pass)
        .then(
            res => {
                if (res == "partner") window.location.href = "partner/main";
                if (res == "admin") window.location.href = "administrator/partners";
                if (res == "superadmin") window.location.href = "administrator/partners";
            }, 
            err => {
                logRef.current.style.border = "1px solid red"
                passRef.current.style.border = "1px solid red"
            })
    }
    const inviteAction = () => {
        authConnector.connect_async("is_reg", {login: login})
        .then(res => {
            if (!res.data.in) {logRef.current.style.border = "1px solid red"; return}
            if (res.data.is_reg) setActiveTab("password");
            if (!res.data.is_reg) setActiveTab("waiting");
        }, err => {
            logRef.current.style.border = "1px solid red"
        })
        
    }
    const AcceptUser = () => {
        if (newPass != newPassRetry){
            newPassRef.current.style.border = "1px solid red"
            newPassRetryRef.current.style.border = "1px solid red"
            return
        }
        authConnector.connect_async("accept_user", {login: login, new_password: newPass, code: code})
        .then(res => {
            inviteAction2(newPass)
        }, err => {
            newPassRef.current.style.border = "1px solid red"
            newPassRetryRef.current.style.border = "1px solid red"
        })
    }

    

    

    
    
    

    let tabsItems = mainTabs.map(value => <div onClick={(e) => {setActiveTab(value.value);}} className={"invite_tab " + (activeTab == value.value ? "invite_tab_active" : "")}>{value.name}</div>)
    return (
        <>
            <div className="inviteHeader">
                <img className="header_img" src={logo} />
            </div>
            <div className="inviteContainer">
                <div className="invite_tabsContainer invite_size">
                    {tabsItems}
                </div>
                {activeTab == "login" && <InTab setLogin={setLogin} login={login} logRef={logRef} inviteAction={inviteAction} setActiveTab={setActiveTab} /> }
                {activeTab == "register" && <RegTab setActiveTab={setActiveTab} /> }
                {activeTab == "forget" && <ForgetTab setActiveTab={setActiveTab} />}
                {activeTab == "password" && <PassTab setPassword={setPassword} passRef={passRef} password={password} inviteAction2={inviteAction2} setActiveTab={setActiveTab}  />}
                {activeTab == "waiting" && <WaitingTab setActiveTab={setActiveTab} text="Ваша заявка на регистрацию на проверке. Администратор рассмотрит ее в близжайшее время." />}
                {activeTab == "success_registration" && <WaitingTab setActiveTab={setActiveTab} text="Вы успешно подали заявку на регистрацию! Администратор рассмотрит ее в близжайшее время" />}
                {activeTab == "forget_success" && <WaitingTab setActiveTab={setActiveTab} text="На указанную при регистрации почту был отправлен e-mail с инструкцией по сбросу пароля" />}
                {activeTab == "init_code" && <CodeTab setCode={setCode} code={code} codeRef={codeRef} AcceptCode={AcceptCode} setActiveTab={setActiveTab} />}
                {activeTab == "new_pass" && <NewPassTab setNewPass={setNewPass} newPassRef={newPassRef} newPassRetryRef={newPassRetryRef} setNewPassRetry={setNewPassRetry} newPas={newPass} newPassRetry={newPassRetry} AcceptUser={AcceptUser} setActiveTab={setActiveTab} />}
            </div>
        </>
        
    )
}