export const navigationItemsSupreme = [
    {value: "departures", name: "Отправления"},
    {value: "partners", name: "Партнеры"},
    {value: "administrators", name: "Администраторы"}, 
    {value: "tickets", name: "Билеты"}, 
    {value: "settings", name: "Настройки"},
    {value: "content", name: "Контент"},
];
export const navigationItems = [{value: "departures", name: "Отправления"},{value: "partners", name: "Парнёры"}, {value: "tickets", name: "Билеты"},{value: "content", name: "Контент"},];
export const supreme_access = ["departures", "partners", "admins", "tickets", "applications", "settings", "content"]
export const classic_admin_access = ["departures", "partners", "tickets", "applications", "content"]