import React, { useEffect, useState } from "react";
import { Button, Input, InputGroup } from "rsuite";
import SearchIcon from '@rsuite/icons/Search';
import { AdminGetAllChatsFetch, AdminGetAllPartnersListToChat, AdminGetChatFetch, AdminLiveChatSendMessageFetch } from "../../connector/connectorV2";
import CloseIcon from '@rsuite/icons/Close';

const AdminLiveChart = ({}) => {
    const [current_chats, set_current_chats] = useState([])
    const [partners, set_partners] = useState([])
    const [active_chat, set_active_chat] = useState(null)
    const [serach_var, set_search_var] = useState("")
    const [active_chat_data, set_active_chat_data] = useState(null)
    const [message, set_message] = useState("")
    const EndSearch = () => {
        set_search_var("")
        GetAllChatsAction()
    }
    const SearchBarAction = (val) => {
        if (val == null || val == ""){
            EndSearch()
            return
        }
        set_search_var(val)
        set_current_chats(partners.filter(partner => partner.partnerId?.includes(val)).map(partner => ({partner_id: partner?.partnerId})))
    }
    const GetAllChatsAction = () => {
        if(serach_var != null && serach_var != "") return;
        AdminGetAllChatsFetch().then(res => {
            set_current_chats(res.data)
        })
    }
    const GetPartnerByPartnerId = (partner_id) => {
        return partners.filter(val => val.partnerId == partner_id)[0]
    }
    const GetChatAction = (main_chat = active_chat) => {
        if (main_chat == null) return;
        if (main_chat.partner_id == null) return;
        AdminGetChatFetch({partner_id: main_chat.partner_id}).then(res => {
            set_active_chat_data(res.data)
        })
    }

    const SendChatMessage = () => {
        if (message == null || message == "") return;
        set_message("")
        const new_messages = [...(active_chat_data.messages || []), {from_type: "ADMIN", text: message, create_datetime: new Date().toLocaleString()}]
        const new_chat_data = {...active_chat_data, ...{messages: new_messages}}
        set_active_chat_data(new_chat_data)
        AdminLiveChatSendMessageFetch({partner_id: active_chat?.partner_id, message: {text: message}})
    }

    const ClickChatItemAction = (new_chat) => {
        set_message("")
        set_active_chat(new_chat)
        GetChatAction(new_chat)
    }
    useEffect(() => {
        AdminGetAllPartnersListToChat().then(res => {
            set_partners(res.data)
        })
        GetAllChatsAction()
    }, [])
    useEffect(() => {
        
        const intervalId = setInterval(() => {GetAllChatsAction()}, 5000);
        const chatIntervalId = setInterval(() => {GetChatAction()}, 5000);
        return () => {
            clearInterval(intervalId);
            clearInterval(chatIntervalId);
        }
    }, [serach_var, active_chat])

    return (
        <div className="adminChatWithPartnersWrapper">
            <div className="chat_choose_admin_wrapper scroll_stylle">
                <div className="bbn52">
                    <InputGroup className="bbn51">
                        <Input value={serach_var} onChange={(val) => {SearchBarAction(val)}} />
                        <InputGroup.Addon>
                            {serach_var == "" && <SearchIcon />}
                            {serach_var != "" && <CloseIcon onClick={EndSearch} />}
                        </InputGroup.Addon>
                    </InputGroup>
                </div>
                {!current_chats?.length && <div className="bbn9">Чатов не найдено</div>}
                {current_chats?.map((val, i) => 
                <div onClick={(e) => {ClickChatItemAction(val)}} className={`admin_chat_item ${active_chat?.partner_id == val?.partner_id ? 'admin_chat_item_active' : ''}`} key={`live-chat-admin-item-key-${i}`}>
                    <div className="ititile">{GetPartnerByPartnerId(val?.partner_id)?.name}&nbsp;{GetPartnerByPartnerId(val?.partner_id)?.surname}&nbsp;{val?.partner_id}</div>
                    <div className="bbn7">
                        {val?.unread_messages_for_admin != 0 && <div className="counter_chat_admin">{val?.unread_messages_for_admin}</div>}
                        <div>{val?.last_message_datetime ? new Date(val?.last_message_datetime).toLocaleString() : null}</div>
                    </div>
                </div>
                )}
            </div>
            <div className="admin_real_chat_wrapper">
                {active_chat != null &&
                <>
                <div className="admin_chat_header">
                    <div className="otitle">Парнер: {GetPartnerByPartnerId(active_chat?.partner_id)?.name}&nbsp;{GetPartnerByPartnerId(active_chat?.partner_id)?.surname}&nbsp;{active_chat?.partner_id}</div>
                </div>
                <div className="message_wrapper scroll_stylle">
                    {active_chat_data?.messages?.map((val, i) => {
                        if(val?.from_type == "ADMIN"){
                            return <div key={`chat-message-item-key-${i}`} className="message message_from_user">{val?.text}</div>
                        } else if (val?.from_type == "PARTNER"){
                            return <div key={`chat-message-item-key-${i}`} className="message message_from_admin ">{val?.text}</div>
                        }
                    })}
                </div>
                <div className="message_drive_wrapper">
                    <InputGroup className="bbn5">
                        <Input value={message} onChange={(val) => {set_message(val)}} />
                        <InputGroup.Addon>
                            <Button className="bbn6" onClick={(e) => {SendChatMessage()}}>Отправить</Button>
                        </InputGroup.Addon>
                    </InputGroup>
                </div>
                </>}
                {active_chat == null && <div className="chat_no_open message_wrapper scroll_stylle"><div>Выберите чат</div></div>}
            </div>
        </div>
    )
}

export default AdminLiveChart;