import React from "react";
import OfficeMap from "../office_map/office_map";


export default function AdministrationContacts({}){
    return (
        <div className="page_bvn2">
            <div className="ititle">Наши офисы</div>
            <div className="bvn2_cards">
                <div className="bvn2_card">
                    <div className="ititle">Главный офис</div>
                    <div className="subtitile">
                        <div>Контакты:</div>
                        <div>тел: <a href="">+7 (999) 999 99 00</a></div>
                        <div>email: <a href="">info@pm.com</a></div>
                    </div>
                    <div className="subsubtitle">
                        Адрес: ул. Ленина 38 стр. 2
                    </div>
                </div>

                <div className="bvn2_card">
                    <div className="ititle">Бухгалтерия</div>
                    <div className="subtitile">
                        <div>Контакты:</div>
                        <div>тел: <a href="">+7 (999) 999 99 00</a></div>
                        <div>email: <a href="">info@pm.com</a></div>
                    </div>
                    <div className="subsubtitle">
                        Адрес: ул. Ленина 38 стр. 2
                    </div>
                </div>

                <div className="bvn2_card">
                    <div className="ititle">Отдел по работе с клиентами</div>
                    <div className="subtitile">
                        <div>Контакты:</div>
                        <div>тел: <a href="">+7 (999) 999 99 00</a></div>
                        <div>email: <a href="">info@pm.com</a></div>
                    </div>
                    <div className="subsubtitle">
                        Адрес: ул. Ленина 38 стр. 2
                    </div>
                </div>
            </div>

            <div className="office_map">
                <OfficeMap height="400px" />
            </div>
        </div>
    )
}