import React from "react";
import logoSVG from './../../../source/svg/logo.svg'
import { useNavigate } from "react-router";

export default function HeaderV2(props){
    const p_name = localStorage.getItem("user_name")
    const p_surname = localStorage.getItem("user_surname")
    const acces = localStorage.getItem("access_token")
    const marker = (p_name != null && p_surname != null && acces != null)
    const navigator = useNavigate()
    return (
        <div className="unpanel_header">
            <div><img src={logoSVG} /></div>
            {marker && <div className="simple_small_btn" onClick={(e) => {navigator("/partner/applications")}}>{p_name} {p_surname}</div>}
            {!marker && <div className="simple_small_btn" onClick={(e) => {navigator("/")}}>Войти</div>}
        </div>
    )
}