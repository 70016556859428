import "./header.css";
import React, { useState, useRef, useEffect } from "react";
import logo from "./../../../source/svg/logo.svg";
import userSVG from "./../../../source/svg/user.svg";
import arrowSVG from "./../../../source/svg/arrow.svg";
import userLogo from "./../../../source/svg/userLogo.svg";
import sortTopSVG from "./../../../source/svg/sortToTop.svg"
import { NavLink } from "react-router-dom";
export default function Header(props){
    const [dropMenu, setDropMenu] = useState(false);

    const dropRef = useRef(null)

    useEffect(() => {
        document.addEventListener("click", closeDrop);
        document.addEventListener("scroll", function () {
            setDropMenu(false)
        });
        return () => {
            document.removeEventListener("click", closeDrop);
            document.removeEventListener("scroll", function () {
                setDropMenu(false)
            });
        }
    }, [])

    function closeDrop (e) {
        if (!dropRef) return
        if (dropRef.current.contains(e.target)) return
        setDropMenu(false)
    }

    let navItems = props.navigationItems.map(value => <NavLink to={`/${props.prefix}/${value.value}`} className={({ isActive, isPending }) =>{return "nav-item " + (isActive ? "nav-item_active" : "")}}>{value.name}</NavLink>)
    return(
        <div className="partnerHeader">
            <img className="header_img not_mobile" src={logo} />
            <div className="navigation">
                {navItems}
            </div>
            <div ref={dropRef} className="header_dropContainer">
                <div onClick={(e) => {setDropMenu(!dropMenu)}} className="header_drop_prev">
                    <div className="mob_header_2">{localStorage.getItem("user_name")} {localStorage.getItem("user_surname")}{(props.anouther_items ? props.anouther_items : "")}</div>
                    {(props.anouther_items2 ? props.anouther_items2 : "")}
                    <img src={userLogo} className="userLogo mobile" />
                </div>
                {dropMenu && props.visible_drop &&
                <div className="header_drop">
                    {props.is_partner && <div className="header_drop_item"><img src={sortTopSVG}/> <NavLink to={`/${props.prefix}/faq`}>База знаний</NavLink></div>}
                    {!props.only_logout && <div className="header_drop_item"><img src={arrowSVG}/> <NavLink to={`/${props.prefix}/settings`}>В личный кабинет</NavLink></div>}
                    <div className="header_drop_item header_drop_item_red"><img src={userSVG} /> <NavLink to="/">Выйти</NavLink></div>
                </div>}
            </div>
        </div>
    )
}