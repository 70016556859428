import React, { useEffect, useState } from "react";
import { NavLink, useParams } from 'react-router-dom';
import ImageModal from "../../atoms/ImageModal/ImageModal";
import { apiUrl, GetPartnerNew } from "../../connector/connectorV2";

const test_structure = [
    {name: "Категория 1", uid: "category-1", subcats: [{name: "Вопрос 1", uid: "question-one"}, {name: "Вопрос 2", uid: "question-one"}]},
    {name: "Категория 2", uid: "category-2", subcats: []}
]

export const FAQWidget = () => {
    const [structure, set_structure] = useState(test_structure);
    return (
        <div className="simple_section">
            <div className="ititle">База знаний</div>
            <div>
                {structure?.map((val, i) => 
                    <NavLink key={`faq-widget-cat-item-key-${i}`} to={`/partner/faq/${val.uid}`} className="faq_item_category">{val.name}</NavLink>
                )}
                
            </div>
            <NavLink to="/partner/faq" className="standart_btn sac1">Перейти к базе</NavLink>
        </div>
    )
}

export function FAQAllPage({}){
    const main_page = {
        title: "FAQ парнера PM",
        text: "Здесь вы найдете всю необходимую для вас информацию. Выбирете интересующий вас раздел"
    }
    
    const { faq_id } = useParams();
    const { category } = useParams();
    const [structure, set_structure] = useState(test_structure);
    const [current_page, set_current_page] = useState(main_page)
    useEffect(() => {
        if (faq_id == null && category == null) return;
        else if (category != null && faq_id != null) {
            set_current_page({title: "Вопрос какой то", text: "Какой то ответ на вопрос"})
        }
        else if (category != null){
            set_current_page({title: "Какая то категория вопросов", text: "Какой то текст описывающий категорию"})
        }
    }, [faq_id, category])
    return (
        <div className="simple_section faq_page">
            <nav className="faq_nav">
                {structure?.map((val, i) => 
                    <>
                        <NavLink key={`cat-key-${i}`} to={`/partner/faq/${val.uid}`} className="category">{val.name}</NavLink>
                        {val?.subcats?.map((subcat, j) => 
                            <NavLink key={`subkay-key-${j}`} to={`/partner/faq/${val.uid}/${subcat.uid}`} className="subcategory">{subcat.name}</NavLink>
                        )}
                    </>)}
            </nav>
            <main className="faq_main">
                <div className="ititle">{current_page?.title}</div>
                <div className="itext">{current_page?.text}</div>
            </main>
        </div>
    )
}