import React, {useState, useEffect} from "react";
import { apiUrl, ArchiveNewFetch, CreateNewFetch, GetAdminNews, UpdateNewFetch } from "../../connector/connectorV2";
import { Accordion, Button, Checkbox, Input, InputGroup, InputNumber, Modal, Pagination, Placeholder, SelectPicker, Stack } from "rsuite";
import ImageModal from "../../atoms/ImageModal/ImageModal";
import UploaderPM from "../../atoms/UploaderPm/UploaderPm";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { CompactTable } from '@table-library/react-table-library/compact';

export default function Content({}){
    const [nodes, setNodes] = useState([])
    const [meta, setMeta] = useState(null)
    const [filter, setFilter] = useState({archived: false})
    const [maxPage, setMaxPage] = useState(0)
    const [page, setPage] = useState(0)
    const limit = 100


    const [addPop, setAddPop] = useState(false)
    const [removeItem, setRemoveItem] = useState(null)
    const [updateItem, setUpdateItem] = useState(null)
    const [itemToCreate, setItemToCreate] = useState({})


    const handelCloseAddPop = ()=>{setAddPop(false);setItemToCreate({})}
    const handleOpenAddPop = ()=>{setAddPop(true)}
    const handleCloseRemovePop = ()=>{setRemoveItem(null)}
    const handleOpenRemovePop = (item) =>{setRemoveItem(item)}
    const handleOpenUpdatePop = (item) =>{setUpdateItem(item)}
    const handleCloseUpdatePop = () => {setUpdateItem(null)}

    useEffect(()=>{
        GetItemsAction(page, limit)
    },[filter])
    const GetItemsAction = (page, limit) => {
        const data = {
            page: page, limit: limit, filter: {archived: (filter.archived ? null : false)}
        }
        GetAdminNews(data).then(res => {
            setNodes(res.data)
            setMaxPage(res.meta.max_page)
            setMeta(res.meta)
        })
    }

    const UpdateFilter = (newValue, key) => {
        let newFilter = {...filter}
        newFilter[key] = newValue;
        setFilter(newFilter)
    }

    const UpdateCreateItemField = (newValue, key) => {
        let newItem = {...itemToCreate}
        newItem[key] = newValue;
        setItemToCreate(newItem)
    }

    const UpdateUpdateItemField = (newValue, key) => {
        let newItem = {...updateItem}
        newItem[key] = newValue;
        setUpdateItem(newItem)
    }

    const fileTypes = ["JPG", "JPEG", "PNG"]


    const ArchiveTicket = (id) => {
        const data = {
            id: id 
        }
        ArchiveNewFetch(data).then(res => {
            handleCloseRemovePop()
            GetItemsAction(page, limit)
        })
    }

    const UpdateAction = () => {
        const data = {
            id: updateItem.id,
            var: {...updateItem, ...{imgs: null}}
        }
        UpdateNewFetch(data).then(res => {
            handleCloseUpdatePop()
            GetItemsAction(page, limit)
        })
    }
    
    const CreateAction = () => {
        const data = {
            var: itemToCreate
        }
        CreateNewFetch(data).then(res => {
            handelCloseAddPop()
            GetItemsAction(page, limit)
        })
    }


    const COLUMNS = [
        { label: 'Заголовок', renderCell: (item) => item?.title },
        {
          label: 'Дата',
          renderCell: (item) =>
            item?.create_datetime ? new Date(item?.create_datetime).toLocaleDateString() : null,
        },
        { label: "Управление", renderCell: (item) => 
            <Stack spacing={5}>
                {!item?.is_archived && 
                <>
                    <Button onClick={(e) => {handleOpenUpdatePop(item)}}><EditIcon/></Button>
                    <Button onClick={(e) => {handleOpenRemovePop(item)}}><TrashIcon /></Button>
                </>
                }
            </Stack>
        },
        {label: "Активен", renderCell: (item) => (item?.archived ? <div className="round red"></div> : <div className="round green"></div>)},
      ];
      const data = { nodes };

      const theme = useTheme([
        getTheme(),
        {
          Table: `
            --data-table-library_grid-template-columns:  300px 120px 200px 100px;
          `,
        },
      ]);
    return (
        <div className="new_page_wrapper">
            <Stack spacing={15} alignItems="center"><div className="main_title">Новости</div><Button color="blue" appearance="primary" onClick={handleOpenAddPop}><AddOutlineIcon/></Button></Stack>

            <Stack spacing={20} wrap={true}>
                <Stack spacing={5}><div>показывать архивные: </div><Checkbox checked={filter?.archived} onChange={(val, boolVal) => UpdateFilter(boolVal, "archived")} /></Stack>
            </Stack>

            <Pagination total={maxPage*limit +1} limit={limit} activePage={page+1} onChangePage={(page)=>{GetItemsAction(page-1, limit); setPage(page-1)}} />
            <CompactTable columns={COLUMNS} data={data} theme={theme} layout={{ custom: true, horizontalScroll: true }} />

            {/* add pop */}
            <Modal open={addPop} onClose={handelCloseAddPop}>
                <Modal.Header>
                <Modal.Title>Добавить новость</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack spacing={10} direction="column" alignItems="flex-start">
                        <Stack spacing={5}><div className="w-25">Заголовок:</div> <Input value={itemToCreate?.title} onChange={(val) => {UpdateCreateItemField(val, "title")}} placeholder="Заголовок" /></Stack>
                        <Stack spacing={5}><div className="w-25">Короткое описание:</div> <Input value={itemToCreate?.short_description} onChange={(val) => {UpdateCreateItemField(val, "short_description")}} placeholder="Короткое описание" /></Stack>
                        <Stack spacing={5}><div className="w-25">Текст:</div> <Input as="textarea" rows={5} value={itemToCreate?.text} onChange={(val) => {UpdateCreateItemField(val, "text")}} placeholder="Текст" /></Stack>
                        <Stack spacing={5}><div className="w-25">Изображения:</div> <UploaderPM UploadBaseFileAction={(file) => {UpdateCreateItemField(file, "imgs")}} fileTypes={fileTypes} /></Stack>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={CreateAction} appearance="primary">
                    Добавить
                </Button>
                <Button onClick={handelCloseAddPop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>

            {/* remove pop */}
            <Modal open={removeItem!=null} onClose={handleCloseRemovePop}>
                <Modal.Header>
                <Modal.Title>Архивировать новость</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Вы действительно хотите это сделать?
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={(e)=>{ArchiveTicket(removeItem.id)}} appearance="primary">
                    Удалить
                </Button>
                <Button onClick={handleCloseRemovePop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>

            {/* update pop */}
            <Modal open={updateItem!=null} onClose={handleCloseUpdatePop}>
                <Modal.Header>
                <Modal.Title>Изменить новость</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack spacing={10} direction="column" alignItems="flex-start">
                    <Stack spacing={5}><div className="w-25">Заголовок:</div> <Input value={updateItem?.title}  placeholder="Заголовок" onChange={(val) => {UpdateUpdateItemField(val, "title")}} /></Stack>
                        <Stack spacing={5}><div className="w-25">Короткое описание:</div> <Input value={updateItem?.short_description} onChange={(val) => {UpdateUpdateItemField(val, "short_description")}} placeholder="Короткое описание" /></Stack>
                        <Stack spacing={5}><div className="w-25">Текст:</div> <Input value={updateItem?.text} as="textarea" rows={5} placeholder="Текст" onChange={(val) => {UpdateUpdateItemField(val, "text")}} /></Stack>
                        <Stack spacing={5}><div className="w-25">Изображения:</div> <ImageModal value={updateItem?.imgs ? updateItem?.imgs.split(";")?.map(img_val => `${apiUrl}/static/${img_val}`) : []} /></Stack>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={(e) => {UpdateAction()}} appearance="primary">
                    Сохранить
                </Button>
                <Button onClick={handleCloseUpdatePop} appearance="subtle">
                    Отмена
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}