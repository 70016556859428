import React from 'react';
import ReactDOM from 'react-dom/client';
import './source/css/index.css';
import App from './App';
import { CustomProvider } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CustomProvider locale={ruRU}>
        <App />
    </CustomProvider>
);
