import React, { useEffect, useState } from "react";
import { NavLink, useParams } from 'react-router-dom';
import ImageModal from "../../atoms/ImageModal/ImageModal";
import { apiUrl, GetPartnerNew } from "../../connector/connectorV2";

export default function NewPage({}){
    const { new_id } = useParams();
    const [inew, set_new] = useState(null);
    useEffect(() => {
        GetPartnerNew(new_id).then(res => {
            set_new(res.data[0])
        })
    }, [])
    return (
        <div className="simple_section">
            <div className="ititle sssss3">{inew?.title} <span className="ssss4">{inew?.create_datetime ? (new Date(inew?.create_datetime).toLocaleDateString()) : null}</span></div>
            <div className="new_wrapper">
                <div className="ssssss1">
                    <ImageModal value={(inew?.imgs ? inew?.imgs.split(";")?.map(img_val => `${apiUrl}/static/${img_val}`) : [])} />
                </div>
                <div className="ssssss2">
                    {inew?.text}
                </div>
            </div>
            {inew == null && <div className="ititle sssss3">404 Новость не найдена</div>}
        </div>
    )
}