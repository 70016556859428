export const event_types = [
    {label: "Конгресс 2024 (Гости)", value: "kongres2024_guest"},
    {label: "Конгресс 2024 (Партнеры)", value: "kongres2024_partner"},
    {label: "Мероприятие 1", value: "event1"},
    {label: "кик офф 2024", value: "event2"},
    {label: "лето москва", value: "summer_moscow"},
    {label: "лето челябинск", value: "summer_chelyaba"},
    {label: "лето иркутск", value: "summer_irkut"},
    {label: "лето краснодар", value: "simmer_krasno"},
    {label: "без типа", value: "non_type"},
    {label: "кик офф 2025 (Гости)", value: "kickoff2025_guest"},
    {label: "кик офф 2025 (Партнеры)", value: "kickoff2025_partner"},
]
export const ticket_type = [
    {label: "Подтвержденные", value: true},
    {label: "Не потдвержденные", value: false}
]